import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginService } from '../../../login/login.service';
import { SampleOrderCreateComponent } from './sample-order-create.component';

const routes: Routes = [
  {
    path: ':supplierId',
    data: {
      title: 'Sample Order',
      urls: [{ title: 'Supplier Dashboard', url: '/' }, { title: 'Basic Supplier' }]
    },
    component: SampleOrderCreateComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    SampleOrderCreateComponent
  ],
  providers: [
  ]
})
export class SampleOrderCreateModule { }
